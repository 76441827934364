import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import Layout from '../components/theme/layout'
import Footer from '../components/theme/footer-landing'
import SEO from '../components/seo'
import { graphql, Link } from "gatsby";
import { get, kebabCase} from "lodash";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import defaultRTRenderOptions from "../../contentful/rich-text-renderer-options"
import styles from '../scss/page.scss'
import {Tab, Row, Col, Nav, Accordion, Card } from 'react-bootstrap'
import { FaMinusCircle, FaPlusCircle, FaRocketchat } from 'react-icons/fa';
import { IoIosMail, IoIosCall } from 'react-icons/io';
import { howToGuideUrl } from "../helpers";

class HowToGuideTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.dogImageMobile = get(this.props, 'data.dogImageMobile')
    this.dogImageDesktop = get(this.props, 'data.dogImageDesktop')
    this.howToGuide = get(this.props, 'data.howToGuide')
    this.iconsInfo = get(this.props, 'data.iconsInfo')
    this.moneyGuarantee = get(this.props, 'data.moneyGuarantee')

    const title = `${this.howToGuide.product} How To Guide Page | Honest Paws®`

    this.seo = {
      children: [],
      disallowIndexing: true,
      meta: {
        title: title,
        description: 'Learn everything you need to know about giving Honest Paws CBD to your pet. Honest Paws offers organic, full-spectrum CBD products for pets.'
      },
      title: title
    }

    this.products = [
      'Oils',
      'Bites',
      'Soft Chews',
      'Cat Soft Chews',
      'Peanut Butter',
      '1000mg',
      'Pet Balm',
      'Calm Vest',
      'Joint Powder',
      'Turkey Tail Mushroom',
      'Pre+ Probiotic',
      '5-in-1 Dog Wash',
      'Wag N\' Brush'
    ]

    this.itemsRefs = []
    this.howToGuide.accordion.items.forEach(( item, index ) => {
      this.itemsRefs[index] = React.createRef();
    })

    this.generateFilters = this.generateFilters.bind(this)
    this.toggleHeaderClass = this.toggleHeaderClass.bind(this);
  }

  /**
   * Generates and return the filter by product on the header
   * @returns {Array}
   */
  generateFilters() {
    let filterOptions = []
    this.products.forEach((product, i) => {
      filterOptions.push(
        <div key={i} className={`filter-product text-center my-2 mr-3 px-2 py-1${product === this.howToGuide.product ? ' active' : ''}`}>
          <a href={`https://www.honestpaws.com/how-to-guide/${kebabCase(product)}`} target="_top">{product}</a>
        </div>
      )
    })

    return filterOptions
  }

  /** Functions belows is a workaround because we need to know accordion status on the header and, by default, it will only show on the content **/
  toggleHeaderClass(index) {
    this.itemsRefs.map((item, i) => {
      if (item.current.classList.contains('open') && item !== this.itemsRefs[index]) item.current.classList.remove('open')
    })

    if (this.itemsRefs) this.itemsRefs[index].current.classList.toggle('open')
  }

  render() {
    const footer = (<Footer />)
    const menu = ''

    const options = defaultRTRenderOptions

    return (
      <Layout containerClassName={`page page-how-to-guide`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden" location={ this.props.location } >
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <section className="page-title-and-summary landing-page-header page-how-to-guide-body text-center px-4 pt-2">
          <div className="row">
            <div className="col-12 text-center htg-filter">Filter by product</div>
            <div className="col-12 p-0 col-lg-6 mx-auto my-4 filter-product-container">
              { this.generateFilters() }
            </div>
            <h1 className="col-12 text-center htg-title mt-4 mb-5">How-to Product Guide</h1>
            <div className="d-flex col-12 col-md-8 offset-md-2 align-items-center htg-main-image">
              <Img fixed={ this.howToGuide.mainImage.fixed } className="mx-auto" alt="How To Guide Main Image" />
            </div>
            <div className="col-12 col-lg-7 text-center summary-text mt-4 mx-auto">{this.howToGuide.summary}</div>
            {this.howToGuide.dosage && (
             <div className="col-8 offset-2 col-lg-4 offset-lg-4 text-center mt-4 dosage-text">{this.howToGuide.dosage}</div>
            )}
          </div>
        </section>
        <section className="custom-tabs-desktop px-4 d-none d-md-block">
          <Tab.Container id="left-tabs-example" defaultActiveKey="custom-tab-0">
            <Row className="col-md-10 mx-auto">
              <Col sm={3}>
                <Nav variant="pills" className="flex-column" title="Guide Navigation">
                  {this.howToGuide.accordion.items.map((item, index) => {
                    return (
                      <Nav.Item key={index}>
                        <Nav.Link eventKey={`custom-tab-${index}`}>
                          { documentToReactComponents(item.header.json, options) }
                          <span className="pl-3 mt-2 fa-minus">
                            <FaMinusCircle title="Active Item"/>
                          </span>
                          <span className="pl-3 mt-2 fa-plus">
                            <FaPlusCircle title="Inactive Item"/>
                          </span>
                        </Nav.Link>
                      </Nav.Item>
                    )
                  })}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {this.howToGuide.accordion.items.map((item, index) => {
                    return (
                      <Tab.Pane eventKey={`custom-tab-${index}`} key={index}>
                        { documentToReactComponents(item.content.json, options) }
                      </Tab.Pane>
                    )
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </section>
        <section className="accordion-mobile px-4 d-md-none">
          <Accordion>
            {this.howToGuide.accordion.items.map((item, index) => {
              return (
                <Card key={index}>
                  <Card.Header id={`accordion-header-${index}`} ref={ this.itemsRefs[index] } >
                    <Accordion.Toggle as="div" eventKey={`accordion-header-${index}`} onClick={this.toggleHeaderClass.bind(this, index)}>
                      <div className="mr-auto">
                        { documentToReactComponents(item.header.json, options) }
                      </div>
                      <span className="pl-3 mt-2 fa-minus">
                        <FaMinusCircle title="Active Item"/>
                      </span>
                      <span className="pl-3 mt-2 fa-plus">
                        <FaPlusCircle title="Inactive Item"/>
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={`accordion-header-${index}`}>
                    <Card.Body>
                      { documentToReactComponents(item.content.json, options) }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )
            })}
          </Accordion>
        </section>
        <section className="cbd-info px-4 pt-0">
          <div className="white-arrow-down mx-auto"></div>
          <div className="row pt-4">
            <div className="d-block d-md-none d-lg-none col-12">
              <Img fluid={ this.dogImageMobile.childImageSharp.fluid } alt="Dog CBD Info" />
            </div>
            <div className="col-12 col-md-5 offset-md-1 col-lg-5 offset-lg-1 mt-4">
              <div className="cbd-info-title">
                We use organic, full-spectrum hemp oil with naturally occurring CBD your pet will love.
              </div>
              <div className="cbd-info-description">
                Full-spectrum CBD oil is considered the highest quality, most effective CBD oil. This is because
                instead of just containing CBD, it contains a full range of cannabinoids (like CBD) and terpenes.
                Terpenes are aromatic oils that come from the same glands as CBD in the hemp plant. We use terpene
                profiles that promote the desired effect. <br/><br/>
                For instance, our Calming Bites have a terpene profile that is known to promote <strong>relaxation and
                stress-relief.</strong> While our Relief Bites have a terpene profile that is known for supporting <strong>healthy
                bones and joints.</strong>
              </div>
              <div className="col-12 mt-4 mt-md-5 col-md-8 pl-md-0 mr-md-8">
                <Img fixed={ this.iconsInfo.childImageSharp.fixed } alt="Icons CBD Info" className="d-none d-md-block" />
                <Img fluid={ this.iconsInfo.childImageSharp.fluid } alt="Icons CBD Info" className="d-md-none"/>
              </div>
            </div>
            <div className="d-none d-md-block d-lg-block col-md-5 col-lg-5">
              <Img fluid={ this.dogImageDesktop.childImageSharp.fluid } alt="Dog CBD Info" />
            </div>
          </div>
        </section>
        <section className="contact-info px-4 pt-0 bg-primary" id="convo-starter-template">
          <div className="natural-arrow-down mx-auto"></div>
          <div className="row pt-5">
            <div className="col-12 col-md-5 mx-auto contact-title text-left text-lg-center">
              Have a question?<br/>
              You can always reach out to our experts.
            </div>
            <span className="col-12 col-md-10 offset-md-1 text-left text-lg-center contact-description pt-5">
              You can always chat with our team of pet nutrition experts and feel A++ about your dog’s health and
              wellness. If anything changes with your dog’s health, lifestyle, or tastebuds, we’re here to help make
              sure they have the best personal support.
            </span>

            <div className="col-12 col-md-8 mx-auto contact-details mt-6">
              <div className="mx-auto"><FaRocketchat title="Chat Icon"/> <a href="#convo-starter-template">Chat with us</a></div>
              <div className="mx-auto"><IoIosMail title="Email Icon"/> Email us: <a href="mailto:ask@honestpaws.com">ask@honestpaws.com</a></div>
              <div className="mx-auto"><IoIosCall title="Phone Icon" /> Call us: <a href="tel:1-844-729-7790">1-844-729-7790</a></div>
            </div>
          </div>
        </section>
        <section className="px-4 pt-0 bg-white guarantee-info">
          <div className="primary-arrow-down mx-auto"></div>
          <div className="row pt-5">
            <div className="col-6 col-md-2 offset-3 offset-md-5">
              <Img fluid={ this.moneyGuarantee.childImageSharp.fluid } alt="Honest Paws Money Back Guarantee"/>
            </div>
            <div className="col-10 col-md-6 mx-auto guarantee-info-title text-center mt-5">
              No if’s, no but’s, no disappointed pups.
            </div>
            <div className="col-12 col-md-8 mx-auto guarantee-info-description text-center mt-4">
              We hold ourselves to the highest of standards when it comes to your satisfaction. If you are not
              satisfied with your product, we do have a <strong>30 day money back guarantee!</strong>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default HowToGuideTemplate

export const howToGuideQuery = graphql`
  query howToGuideById($id: String) {
    site {
      siteMetadata {
        title
      }
    }
    howToGuide: contentfulHowToGuide(id: {eq: $id}) {
      accordion {
        items {
          header {
            json
          }
          content {
            json
          }
        }
      }
      dosage
      id
      mainImage {
        fixed(height: 350) {
          ...contentfulImageFixed
        }
        fluid {
          ...contentfulImageFluid
        }
      }
      product
      summary
    }
    dogImageMobile: file(relativePath: { eq: "dog_mobile.png" }) {
      childImageSharp {
        fluid (maxWidth: 350, maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dogImageDesktop: file(relativePath: { eq: "dog_desktop.png" }) {
      childImageSharp {
        fluid (maxWidth: 480, maxHeight: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iconsInfo: file(relativePath: { eq: "cbd_info_icons.png" }) {
      childImageSharp {
        fixed(height: 87, width:478) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 240) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    moneyGuarantee: file(relativePath: { eq: "money_guarantee.png" }) {
      childImageSharp {
        fluid (maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
