import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import {omit} from 'lodash'
import Img from 'gatsby-image'
import footer from './footer.scss'

class Footer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data } = this.props

    const props = omit(this.props, ['data'])

    return (
      <footer { ...props }>
        <section className="bg-white footer-landing-container">
          <div className="container">
            <hr/>
            <div className="row">
              <div className="col-8 offset-2 col-md-2 offset-md-0">
                <Img fluid={data.footerLogo.childImageSharp.fluid} alt="Honest Paws Logo" />
              </div>
              <div className="col-12 col-md-4 text-center text-md-left">
                <p className="mt-2">
                  &copy;{ (new Date()).getFullYear() } Honest Paws, LLC. All rights reserved
                </p>
              </div>
              <div className="col-12 col-md-5 offset-md-1 d-flex flex-column flex-md-row">
                <Link to="/terms-and-conditions/" className="mx-auto">Terms and Conditions</Link>
                <Link to="/privacy-policy/" className="mx-auto">Privacy Policy</Link>
                <Link to="/contact-us/" className="mx-auto">Contact Us</Link>
                <Link to="/return-policy/" className="mx-auto">Return Policy</Link>
              </div>
            </div>
          </div>
        </section>
      </footer>
    )
  }
}



export default (props) => {
  const data = useStaticQuery(graphql`
    query FooterLandingQuery {
      site {
        siteMetadata {
          title
        }
      }
      footerLogo: file(relativePath: { eq: "honestpaws-logo-blue.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 45) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Footer  {...props} data={data} />
  )
};
